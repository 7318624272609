<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0" v-if="serverData">
          <!--          <div class="col-12 col-md-6 col-md-4 h-75px" v-if="!isClient">-->
          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.title"
              label="Title"
              clearable
              outlined
              dense
              :error-messages="titleErrors"
              @input="$v.formData.title.$touch()"
              @blur="$v.formData.title.$touch()"
            />
          </div>
          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.receiver_type"
              label="Receiver type"
              item-text="text"
              item-value="index"
              :items="receivers"
              clearable
              outlined
              dense
              :error-messages="receiver_typeErrors"
              @input="$v.formData.receiver_type.$touch()"
              @blur="$v.formData.receiver_type.$touch()"
            />
          </div>
          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.receiver_values"
              :label="userDetails.label"
              :items="userDetails.list"
              item-text="text"
              item-value="index"
              :multiple="userDetails.isMultiple"
              :small-chips="userDetails.isMultiple"
              :deletable-chips="userDetails.isMultiple"
              clearable
              outlined
              dense
              :error-messages="receiver_valuesErrors"
              @input="$v.formData.receiver_values.$touch()"
              @blur="$v.formData.receiver_values.$touch()"
            />
          </div>
          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.action_type_id"
              label="Action type"
              :items="serverData.actions"
              item-text="name"
              item-value="id"
              clearable
              outlined
              dense
              :error-messages="action_type_idErrors"
              @input="$v.formData.action_type_id.$touch()"
              @blur="$v.formData.action_type_id.$touch()"
            />
          </div>
          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.action_id"
              :label="actionNotificationDetails.label"
              :items="actionNotificationDetails.list"
              item-value="id"
              item-text="label"
              :multiple="actionNotificationDetails.isMultiple"
              :small-chips="actionNotificationDetails.isMultiple"
              :deletable-chips="actionNotificationDetails.isMultiple"
              clearable
              outlined
              dense
              :error-messages="action_idErrors"
              @input="$v.formData.action_id.$touch()"
              @blur="$v.formData.action_id.$touch()"
            />
          </div>

          <!--              :class="{ 'col-md-6': isClient }"-->
          <template v-if="formData.action_type_id === 1">
            <div class="col-12 col-md-6 h-75px">
              <v-autocomplete
                v-model="formData.days"
                label="Day"
                item-text="text"
                item-value="index"
                :items="serverData.days"
                multiple
                small-chips
                deletable-chips
                clearable
                outlined
                dense
                :error-messages="daysErrors"
                @input="$v.formData.days.$touch()"
                @blur="$v.formData.days.$touch()"
              />
            </div>

            <div class="col-12 col-md-6 h-75px">
              <v-text-field
                v-model="formData.at"
                label="Time"
                clearable
                outlined
                dense
                :error-messages="atErrors"
                @input="$v.formData.at.$touch()"
                @blur="$v.formData.at.$touch()"
              />
            </div>
          </template>

          <template
            v-if="
              formData.action_type_id === 2 || formData.action_type_id === 3
            "
          >
            <div class="col-12 h-75px">
              <v-autocomplete
                v-model="formData.template_id"
                label="Template"
                item-text="text"
                item-value="index"
                :items="serverData.templates"
                clearable
                outlined
                dense
                :error-messages="template_idErrors"
                @input="$v.formData.template_id.$touch()"
                @blur="$v.formData.template_id.$touch()"
              />
            </div>
          </template>
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { SET_ACTIONS_DIALOG_SERVER_DATA } from "@/core/services/store/notifications.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      title: { required },
      receiver_type: { required },
      receiver_values: { required },
      action_type_id: { required },
      action_id: { required },
      days: {
        required: requiredIf(function() {
          return this.formData.action_type_id === 1;
        }),
      },
      at: {
        required: requiredIf(function() {
          return this.formData.action_type_id === 1;
        }),
      },
      template_id: {
        required: requiredIf(function() {
          return Boolean(
            this.formData.action_type_id === 2 ||
              this.formData.action_type_id === 3
          );
        }),
      },
    },
  },
  data: () => ({
    dialog: false,
    formData: {
      title: null,
      receiver_type: null,
      receiver_values: null, // type is Array for multiple select and integer for single
      action_type_id: null,
      action_id: null, // type is Array for multiple select and integer for single
      days: [1, 2, 3, 4, 5, 6, 7],
      at: "",
      template_id: null,
    },
    disableWatchers: true,
    isForEdit: false,
    userDetails: { label: "Receiver", list: [], isMultiple: true },
    actionNotificationDetails: {
      label: "Action",
      list: [],
      isMultiple: false,
    },
  }),
  methods: {
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/settings/notifications/edit", sendData)
          .then(({ data }) => {
            this.isForEdit = true;
            if (!this.serverData) {
              this.$store.commit(SET_ACTIONS_DIALOG_SERVER_DATA, data);
            }
            this.formData = {
              // id: sendData.id,
              ...data.notification,
              action_type_id: data.notification.action_id,
              action_id: data.notification.values,
            };

            this.disableWatchers = false;
            this.dialog = true;
          })
          .catch(() => {
            this.dialog = false;
          })
          .finally(() => {
            this.pageLoader(false);
          });
      } else {
        if (!this.serverData) {
          this.pageLoader(true);
          ApiService.post("/settings/notifications/create")
            .then(({ data }) => {
              this.isForEdit = false;
              this.$store.commit(SET_ACTIONS_DIALOG_SERVER_DATA, data);
              this.pageLoader(false);
            })
            .catch(() => {
              this.dialog = false;
              this.pageLoader(false);
            });
        }
        this.disableWatchers = false;
        this.dialog = true;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        // this.dialog = !this.dialog;
        this.dialog = false;
      } else {
        this.loadDataFromServer();
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");

        if (fieldName === "at") {
          const reg = RegExp(
            // eslint-disable-next-line
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
          );
          if (!reg.test(this.formData.at)) {
            errors.push("Time have to be in 24h HH:MM format");
          }
        }
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // if (this.$store.getters.isClient) delete this.formData.customer_id;

      const payload = {
        title: this.formData.title,
        receiver_type: this.formData.receiver_type,
        receiver_values: this.formData.receiver_values,
        action_id: this.formData.action_type_id,
        values: this.formData.action_id,
        days: this.formData.days,
        at: this.formData.at,
        template_id: this.formData.template_id,
      };

      if (!this.userDetails.isMultiple) {
        payload.receiver_values = [this.formData.receiver_values];
      }
      if (!this.actionNotificationDetails.isMultiple) {
        payload.values = [this.formData.action_id];
      }

      let actionUrl = "store";
      if (this.actionType) {
        actionUrl = "update";
        payload.id = this.formData.id;
      }

      this.pageLoader(true);
      ApiService.post(`/settings/notifications/${actionUrl}`, payload)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetFormData() {
      this.formData = {
        title: null,
        receiver_type: null,
        receiver_values: null,
        action_type_id: null,
        action_id: null,
        days: [1, 2, 3, 4, 5, 6, 7],
        at: "",
        template_id: null,
      };
      this.disableWatchers = true;
      this.isForEdit = false;
      this.userDetails = { label: "Receiver", list: [], isMultiple: true };
      this.actionNotificationDetails = {
        label: "Action",
        list: [],
        isMultiple: false,
      };
    },
  },
  computed: {
    receivers: function() {
      return [
        { index: "users", text: "Users" },
        { index: "customer_accounts", text: "Customer accounts" },
        { index: "end_customer", text: "Customer" },
      ];
    },
    serverData: function() {
      return this.$store.getters.getNOTIFICATIONSActionsDialogServerData;
    },
    //if true, we are editing item, false we are adding
    actionType: function() {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function() {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function() {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    titleErrors: function() {
      return this.handleFormValidation("title");
    },
    receiver_typeErrors: function() {
      return this.handleFormValidation("receiver_type");
    },
    receiver_valuesErrors: function() {
      return this.handleFormValidation("receiver_values");
    },
    action_type_idErrors: function() {
      return this.handleFormValidation("action_type_id");
    },
    action_idErrors: function() {
      return this.handleFormValidation("action_id");
    },
    daysErrors: function() {
      return this.handleFormValidation("days");
    },
    atErrors: function() {
      return this.handleFormValidation("at");
    },
    template_idErrors: function() {
      return this.handleFormValidation("template_id");
    },
    // isClient() {
    //   return this.$store.getters.currentUser.data.is_client;
    // },
    selectedReceiver() {
      return this.formData.receiver_type;
    },
    selectedAction() {
      return this.formData.action_type_id;
    },
  },
  watch: {
    selectedReceiver: function(newValue, oldValue) {
      // console.log("newValue selectedReceiver", newValue);
      if (newValue && !this.disableWatchers) {
        if (newValue !== oldValue) {
          if (newValue === "users") {
            this.userDetails.label = "Users";
            this.userDetails.list = this.serverData.users;
            this.userDetails.isMultiple = true;

            if (!this.isForEdit) {
              this.formData.receiver_values = [];
            }
          } else if (newValue === "customer_accounts") {
            this.userDetails.label = "Customer accounts";
            this.userDetails.list = this.serverData.customer_accounts;
            this.userDetails.isMultiple = true;

            if (!this.isForEdit) {
              this.formData.receiver_values = [];
            }
          } else if (newValue === "end_customer") {
            this.userDetails.label = "Customer";
            this.userDetails.list = this.serverData.customers;
            this.userDetails.isMultiple = false;

            if (!this.isForEdit) {
              this.formData.receiver_values = null;
            } else if (this.formData.receiver_values) {
              this.formData.receiver_values = this.formData.receiver_values[0];
            }
          }

          // this.isForEdit = false;
        }
      } else {
        this.userDetails = { label: "Receiver", list: [], isMultiple: true };
      }
    },
    selectedAction: function(newValue, oldValue) {
      // console.log("newValue selectedAction", newValue);
      if (newValue && !this.disableWatchers) {
        if (newValue !== oldValue) {
          if (newValue === 1) {
            this.formData.template_id = null;
            this.actionNotificationDetails.isMultiple = false;

            // console.log("this.isForEdit", this.isForEdit);
            if (!this.isForEdit) this.formData.action_id = null;
            else if (this.formData.action_id) {
              this.formData.action_id = this.formData.action_id[0];
            }
          } else {
            this.formData.days = [1, 2, 3, 4, 5, 6, 7];
            this.formData.at = "";

            if (newValue === 2) {
              this.actionNotificationDetails.isMultiple = true;
              if (!this.isForEdit) this.formData.action_id = [];
            } else if (newValue === 3) {
              this.actionNotificationDetails.isMultiple = false;

              if (!this.isForEdit) this.formData.action_id = null;
              else if (this.formData.action_id) {
                this.formData.action_id = this.formData.action_id[0];
              }
            }
          }
          this.actionNotificationDetails.list = this.serverData.values.filter(
            (item) => item.notification_action_id === newValue
          );
          this.actionNotificationDetails.label = this.serverData.actions.find(
            (item) => item.id === newValue
          ).name;

          this.isForEdit = false;
        }
      } else {
        this.actionNotificationDetails = {
          label: "Action",
          list: [],
          isMultiple: false,
        };
      }
    },
  },
};
</script>
